import React from 'react'
import queryString from 'query-string'
import { withRouter } from 'react-router-dom'
import SearchForm from '../atoms/SearchForm'
import Loader from '../atoms/Loader'
import ResultsList from '../molecules/ResultsList'
import API from '../../utils/api'
import { dropTags } from '../../utils/helpers'
import config from '../../utils/config'
import _ from 'lodash'

class Results extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      inputText: queryString.parse(this.props.location.search).term,
      results: [],
      activeNamespace: queryString.parse(this.props.location.search).project,
      isLoading: true
    }

    this.handleFormSubmit = this.handleFormSubmit.bind(this)
    this.handleInputChange = this.handleInputChange.bind(this)
    this.handleOptionChange = this.handleOptionChange.bind(this)
    this.fetch = this.fetch.bind(this)
    this.fetchResults = this.fetchResults.bind(this)
  }

  handleFormSubmit(event) {
    event.preventDefault()
    const { inputText, activeNamespace } = this.state
    const url = `/results/?term=${inputText}&project=${activeNamespace}`
    this.props.history.push(url)
  }

  handleInputChange(event) {
    event.preventDefault()
    this.setState({ inputText: event.target.value })
  }

  handleOptionChange(event) {
    event.preventDefault()
    const activeNamespace = event.target.value
    this.setState({ activeNamespace })
    this.fetchResults(activeNamespace, this.state.inputText)
  }

  async fetch(project, term) {
    return API.get(`${project}?searchQuery=${term}`)
  }

  async fetchResults(project, term) {
    this.setState({ isLoading: true })
    let results = []

    if (project === 'all') {
      const namespaces = config.namespaces.filter(namespace => namespace.value !== 'all')
      const requests = namespaces.map(namespace => this.fetch(namespace.value, term).catch(() => console.log('error in one of them')))
      const responses = await Promise.all(requests)
      const data = responses.map(response => response.data)
      results = dropTags([].concat(...data), 'all')
    } else {
      try {
        const response = await this.fetch(project, term)
        results = dropTags(await response.data, project)
      } catch (errors) {
        //console.log(errors)
      }
    }

    this.setState({ results: results.slice(0, 10), isLoading: false })

    // Fetch remaining results in the background
    if (results.length > 10) {
      const remainingResults = results.slice(10)
      this.setState(prevState => ({
        results: [...prevState.results, ...remainingResults]
      }))
    }
  }

  componentDidMount() {
    const { term, project } = queryString.parse(this.props.location.search)
    this.fetchResults(project, term)
  }

  render() {
    const { results, activeNamespace, inputText, isLoading } = this.state
    return (
      <div className='results tl pb3'>
        <SearchForm
          defaultValue={inputText}
          onInputChange={this.handleInputChange}
          onSubmit={this.handleFormSubmit}
          onOptionChange={this.handleOptionChange}
          activeNamespace={activeNamespace}
        />
        <div className="mt4">
          {isLoading
            ? <Loader />
            : _.isEmpty(results)
              ? <p className="b">No Results</p>
              : (
                <div>
                  <p className="b">Search Results:</p>
                  <ResultsList
                    results={results}
                    itemsPerPage={10}
                  />
                </div>
              )
          }
        </div>
      </div>
    )
  }
}

export default withRouter(Results)
