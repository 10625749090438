import React from 'react'
import ReactDOM from 'react-dom'
import './assets/css/index.scss'
import App from './components/App'
import reportWebVitals from './utils/reportWebVitals'
import { fetchTiipTopics } from './utils/helpers.js';

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
)

// get TIIP topics from Content API to build URLs
export async function getStaticProps() {
  const tiipTopicsData = fetchTiipTopics()
  return {
    props: {
      tiipTopicsData
    }
  }
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
