module.exports = {
    apiBaseUrl: 'https://api.ttc.io/',
    namespaces: [
        { value: 'all', label: 'All'},
        { value: 'eti', label: 'Exposing The Invisible' },
        { value: 'gr', label: 'The Glass Room' },
        { value: 'tiip', label: 'The Influence Industry' },
        { value: 'tt2023', label: 'Tactical Tech'}
    ],
    publicUrls: {
        'eti' : 'https://exposingtheinvisible.org/',
        'tt2023' : 'https://tacticaltech.org/',
        'gr' : 'https://theglassroom.org/',
        'tiip' : 'https://influenceindustry.org/'
    },
    searchFields: 'searchFields=description',
}  
