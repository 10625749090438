import React from 'react';

import logo from '../../assets/svg/tt_logo.svg'

function Header() {
  function handleOnLogoClick() {
    window.location.href = '/'
  }

  return(
    <div className="header bg-black">
        <img src={logo} className="db pointer" onClick={handleOnLogoClick} alt="tactical tech logo">
        </img>
    </div>
  )
}

export default Header
