import React from 'react'

import SearchForm from '../atoms/SearchForm'

import { Redirect } from 'react-router-dom'

class Home extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      inputText: '',
      isSubmitted: false
  }
  this.handleFormSubmit = this.handleFormSubmit.bind(this)
  this.handleInputChange = this.handleInputChange.bind(this)
    
  }

  handleFormSubmit(event) {
    event.preventDefault();
    this.setState({isSubmitted: true})
  }

  handleInputChange(event) {
    event.preventDefault();
    this.setState({inputText: event.target.value})
  }

  render() {
    const { isSubmitted, inputText } = this.state
    if(isSubmitted) {
      return (
        <Redirect to={`/results/?term=${inputText}&project=all`} />
      )
    }
    return (
      <div className="container">
        <SearchForm
          onInputChange={this.handleInputChange}
          onSubmit={this.handleFormSubmit}
          className="home__form"
        />
      </div>
    )
  }
}

export default Home;