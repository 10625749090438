import React, { useState, useEffect } from 'react';
import { getUrl } from '../../utils/helpers';
import _ from 'lodash';

function Result(props) {
  const [urlToGo, setUrlToGo] = useState('');

  useEffect(() => {
    async function fetchData() {
      try {
        const url = await getUrl(props.item);
        setUrlToGo(url);
      } catch (error) {
        console.error(error);
      }
    }

    fetchData();
  }, [props.item]);

  return (
    <div className="mt4 result">
      <a href={urlToGo} rel="noopener noreferrer">
        <p className="pointer underline black">{urlToGo}</p>
      </a>
      <a href={urlToGo} rel="noopener noreferrer">
        <h3 className="mt1 no-underline black b p">{props.item.title}</h3>
      </a>
      <p className="mt1">
        {_.truncate(props.item.description, { 'length': 250, 'separator': '...' })}
      </p>
    </div>
  );
}

export default Result;