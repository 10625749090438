import React from 'react';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom'

import Home from './organisms/Home'
import Results from './organisms/Results'
import Header from './molecules/Header'

function App() {
  return (
    <div className="App">
      <Header />
      <main className="center content-max-width ph3">
        <BrowserRouter forceRefresh={true} >
          <Switch>
            <Route exact path="/results" component={Results}  />
            <Route exact path="/" component={Home} />
            <Route exact path="/:slug">
              <Redirect to="/" />
            </Route>
          </Switch>
        </BrowserRouter>
      </main>
    </div>
  );
}

export default App;
