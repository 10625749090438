import React, { useEffect, useState } from 'react'
import ReactPaginate from 'react-paginate'
import Result from '../atoms/Result'
import _ from 'lodash'

function Results({ currentResults }) {
  return (
    <>
      {_.map(currentResults, (result, key) => (
        <Result item={result.item} key={key} />
      ))}
    </>
  )
}

function ResultsList(props) {
  const [currentResults, setCurrentResults] = useState(null)
  const [pageCount, setPageCount] = useState(0)
  const [itemOffset, setItemOffset] = useState(0)

  useEffect(() => {
    const endOffset = itemOffset + props.itemsPerPage
    setCurrentResults(props.results.slice(itemOffset, endOffset))
    setPageCount(Math.ceil(props.results.length / props.itemsPerPage))
  }, [itemOffset, props.itemsPerPage, props.results])

  const handlePageClick = (event) => {
    const newOffset = (event.selected * props.itemsPerPage) % props.results.length
    setItemOffset(newOffset)
  }

  return (
    <div className="mt2">
      <Results currentResults={currentResults} />
      {_.size(props.results) > props.itemsPerPage && (
        <ReactPaginate
          breakLabel="..."
          nextLabel=">"
          onPageChange={handlePageClick}
          pageRangeDisplayed={props.itemsPerPage}
          pageCount={pageCount}
          previousLabel="<"
          renderOnZeroPageCount={null}
          pageLinkClassName="mh2 pointer f4"
          containerClassName="flex ma0 list pa0 mt4 gray"
          previousLinkClassName="pointer f4"
          nextLinkClassName="pointer f4"
          activeLinkClassName="b black"
        />
      )}
    </div>
  )
}

export default ResultsList
