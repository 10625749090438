import React from 'react'
import logo from '../../assets/svg/tt_logo_line.svg'
import config from '../../utils/config'
import _ from 'lodash'

function SearchForm(props) {
  return (
    <form className={`${props.className} flex flex-column search-form`} onSubmit={props.onSubmit}>
      <img className="mb2" src={logo} alt="TTC logo inline" />
      <div className="flex flex-column flex-row-ns">
        {props.defaultValue ? (
          <input
            className="search-form__input pl2"
            defaultValue={props.defaultValue}
            type="text"
            onChange={props.onInputChange}
          />
        ) : (
          <input className="search-form__input pl2" type="text" onChange={props.onInputChange} />
        )}
        <input className="search-form__button bg-black white pointer mt1 mt0-ns" type="submit" value="Search" />
      </div>
      {props.onOptionChange ? (
        <select defaultValue={props.activeNamespace} onChange={props.onOptionChange} className="mt3 pl2 b pointer">
          {_.map(config.namespaces, (namespace, key) => (
            <option className="mt2" key={key} value={namespace.value}>
              {namespace.label}
            </option>
          ))}
        </select>
      ) : (
        ''
      )}
    </form>
  )
}

export default SearchForm
