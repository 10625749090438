import conf from './config.js'
import axios from 'axios'

const isLegacyContent = (publicationDate) => {
  const cutoffDate = new Date('2023-05-05T00:00:00.000Z')
  const pubDate = new Date(publicationDate)
  return pubDate < cutoffDate
}

export const getUrl = async (item) => {
  if (!item.project || item.project === 'eti') return getUrlEti(item)
  if (item.project === 'tacticaltech-org-2023') return await getUrlTtc(item)
  if (item.project === 'gr') return getUrlGr(item)
  if (item.project === 'tiip') return getUrlTiip(item)
  return getUrlEti(item)
}

export const getUrlEti = (item, namespace = 'eti') => {
  let url = `${conf.publicUrls[namespace]}${item.locale}/`
  if (['theme', 'page', 'landingPage'].includes(item.contentType)) return url + item.slug
  if (item.contentType !== 'news') url += `${item.contentType}s/`
  return url + item.slug
}

export const getUrlGr = (item, namespace = 'gr') => {
  let url = `${conf.publicUrls[namespace]}${item.locale}`
  if (item.contentType === 'feature' && item.links?.[0]) return url + item.links[0].link
  return url + item.slug
}

export const getUrlTiip = (item, namespace = 'tiip') => {
  let url = `${conf.publicUrls[namespace]}${item.locale}/`
  const topicObj = tiipTopicsList.find(o => o.uuid === item.topic)
  if (item.contentType === 'page') return url + `project/${item.slug}`
  if (item.contentType === 'case_study' && topicObj) return url + `${topicObj.slug}/case-studies/${item.slug}`
  if (item.contentType === 'theme') return url + `resources/${item.contentType}s/${item.slug}`
  if (item.contentType === 'topic') return url + item.slug
  if (item.contentType === 'highlight') return url + `${item.contentType}s/${item.slug}`
  if (item.contentType === 'landing_page') {
    if (item.slug === 'home') return url
    if (item.slug === 'resources') return url + item.slug
    if (topicObj) return url + `${topicObj.slug}/${item.slug}`
  }
  if (item.contentType === 'resource') {
    if (topicObj) return url + `${topicObj.slug}/${item.contentType}s/${item.slug}`
    return url + `${item.contentType}s/${item.slug}`
  }
  if (item.contentType === 'company') return url + `explorer/companies/${item.slug}`
  return url
}

export const getUrlTtc = async (item, namespace = 'tt2023') => {
  const { contentType, publicationDate, category, slug } = item
  const publicUrl = conf.publicUrls[namespace]
  if (contentType === 'news') {
    if (isLegacyContent(publicationDate)) return `${publicUrl}news/${slug}`
    try {
      const { data: newsCategory } = await axios.get(`${conf.apiBaseUrl}${namespace}/?uuid=${category}`)
      return `${publicUrl}news/${newsCategory.slug}/${slug}`
    } catch (error) {
      console.error(error)
    }
  }
  const contentTypePath = ['resource', 'project', 'partner'].includes(contentType)
    ? `${contentType}s/`
    : ''

  return `${publicUrl}${contentTypePath}${slug}`
}

export const dropTags = (items, namespace) => {
  const contentTypeFilter = {
    eti: ['tag', 'topic', 'role', 'workshopTopic', 'guideCategory', 'feature', 'person'],
    gr: [
      'online_exhibit_type', 'publisher', 'slideshow', 'carousel', 'contentbox', 'related_content',
      'press', 'press_quote', 'contentboxslider', 'info_text', 'artist', 'three_text_columns', 'upcoming',
      'feature', 'quote'
    ],
    tiip: ['shortcuts_list', 'resource_group', 'person', 'card'],
    tt2023: ['update', 'person', 'press_coverage', 'media_outlet', 'timeline_entry', 'press',
      'timeline_year_section', 'navigation', 'news_category', 'role', 'topic', 'region', 'language',
      'medium', 'audience', 'work_area'],
  }

  const excludedSuffix = '_module'

  return items.filter(obj => {
    const { contentType, project } = obj.item
    if (namespace === 'all') {
      const excludedTypes = contentTypeFilter[project] || []
      return (
        !excludedTypes.includes(contentType) &&
        !(project === 'tt2023' && contentType.endsWith(excludedSuffix))
      )
    } else {
      const excludedTypes = contentTypeFilter[namespace] || []
      return (
        !excludedTypes.includes(contentType) &&
        !(namespace === 'tt2023' && contentType.endsWith(excludedSuffix))
      )
    }
  })
}


const tiipTopicsUrl = `${conf.apiBaseUrl}tiip/?contentType=topic`
let tiipTopicsList = []

export async function fetchTiipTopics() {
  try {
    const tiipTopics = await axios.get(tiipTopicsUrl)
    tiipTopicsList = tiipTopics.data
  } catch (error) {
    console.error(error)
  }
}

fetchTiipTopics()
